import React from "react";
import { Link } from "gatsby";

const Navbar = () => {
  const navbarItems = [
    {
      link: "/",
      text: "Home"
    },
    {
      link: "/about",
      text: "About"
    },
    {
      link: "/contact",
      text: "Contact"
    }
  ];

  const items = navbarItems.map(item => {
    return (
      <Link
        to={item.link}
        className="block mt-4 md:inline-block md:mt-0 mr-6 no-underline text-white"
      >
        {item.text}
      </Link>
    );
  });

  return <div className="text-sm">{items}</div>;
};

export default Navbar;
