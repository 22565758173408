import React from "react";
import { Link } from "gatsby";

const Footer= () => {
    return (
<footer className="bg-blue-300">
            <div className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm">
              <p className="text-pink-600">
                Created by{" "}
                <a className="font-bold no-underline ">
                    <Link to="/about" className="flex items-center no-underline text-pink-600"> NeuroClau</Link>
                </a>
              </p>

              <p>
                <a href="/" className="font-bold no-underline text-pink-600" >
                  NeuroClau
                </a>
              </p>
            </div>
          </footer>)
}

export default Footer;